<template>
    <section class="main container terms-section">
        <div class="row">
            <div class="col">
                <h2>Términos de uso</h2>
                <card class="terms-and-conditions">
                    <separator />
                    <div class="terms-frame">
                        <!-- <h2>TÉRMINOS DE USO</h2> -->
                        <p>Para el registro de usuario, acceso y uso de la plataforma <strong>Kn·U</strong>, el usuario, el padre y/o tutor acepta los presentes Términos de Uso.</p>

                        <p>Los presentes términos de uso regulan la forma en que se puede utilizar la plataforma <strong>Kn·U</strong> así como
                        cualquier servicio del sitio <a href="http://www.kn-u.org">www.kn-u.org</a> (sitio), los contenidos publicados en ellos y el uso de los
                        usuarios. La plataforma tecnológica <strong>Kn·U</strong> es propiedad de Knotion, S.A. de C.V. (KNOTION)
                        licenciantes o titulares de los derechos y su uso se rige de acuerdo a los siguientes términos y la política
                        de privacidad que puedes consultar en <a href="http://www.kn-u.org">www.kn-u.org</a> y las Políticas de Coursera que se pueden
                        consultar en <a href="https://learner.coursera.help/hc/es/sections/201906986-Pol%C3%ADticas-de-Coursera" target="_blank" >https://learner.coursera.help/hc/es/sections/201906986-Pol%C3%ADticas-de-Coursera</a></p>

                        <p>El uso del sitio así como la plataforma tecnológica <strong>Kn·U</strong> y sus contenidos implica la aceptación de los
                        presentes términos de uso y la política de privacidad de <strong>Kn·U</strong> y Coursera Inc. La aceptación es
                        realizada por el usuario, el padre o tutor del usuario que dará uso de la plataforma tecnológica <strong>Kn·U</strong> en
                        el curso contratado. De no estar de acuerdo con los términos y condiciones establecidos, deberá
                        abandonar el sitio en virtud del desacuerdo así como de abstenerse de utilizar cualquier elemento de La
                        plataforma tecnológica <strong>Kn·U</strong>.</p>

                        <p>KNOTION ha desarrollado una plataforma tecnológica, que promueve cursos especializados y
                        certificaciones a partir de preparatoria en adelante para todas las edades denominada <strong>Kn·U</strong>, en su
                        conjunto y en lo sucesivo <strong>Kn·U</strong>.</p>

                        <p>El usuario, padre o tutor reconoce y acepta que ha sido su voluntad inscribirse o inscribir a el usuario a
                        alguno de los cursos que ofrece <strong>Kn·U</strong>. De igual forma comprende y acepta que KNOTION es el
                        proveedor exclusivo de los cursos por medio del cual el usuario podrá contratar el curso que se adapte
                        a sus necesidades.</p>

                        <p>El usuario, padre o tutor comprende y acepta que el funcionamiento de la plataforma tecnológica <strong>Kn·U</strong>
                        está sujeto a contar con un equipo Laptop o PC, navegador Google Chrome (recomendado), Microsoft
                        Edge, Firefox o Safari si el acceso es mediante sitio <a href="http://www.kn-u.org">www.kn-u.org</a>, deberá de contar preferentemente
                        con una cuenta de correo y con una buena conexión a internet, asumiendo todas las partes que podrían
                        existir interrupciones temporales de la plataforma tecnológica por causas relacionadas al
                        mantenimiento, actualizaciones y/o cualquier otro relacionado directa o indirectamente, o incluso por
                        motivos externos o ajenos a <strong>Kn·U</strong>, que relevan de responsabilidad a este último.</p>

                        <p>El usuario, padre o tutor que utilice la plataforma tecnológica <strong>Kn·U</strong> acepta conocer las disposiciones
                        que regulan el uso del mismo y que a continuación se establecen, en el entendido de que <strong>Kn·U</strong> es
                        simplemente una herramienta que ofrece cursos especializados y certificados a partir de preparatoria.
                        Para el registro de la plataforma tecnológica <strong>Kn·U</strong>, el usuario, padre o tutor deberá proporcionar sus
                        datos como usuario, contraseña, nombre, apellido paterno, apellido materno, fecha de nacimiento y
                        correo electrónico.</p>

                        <p>El registro en la plataforma <strong>Kn·U</strong> es solo para mayores de 18 años edad, por lo cual a partir de este
                        momento el usuario, padre o tutor se responsabiliza enteramente de dicha declaración y, en
                        consecuencia <strong>Kn·U</strong> se reserva el derecho de bloquear o cancelar la cuenta en caso de detectar
                        falsedad en los datos de registro.</p>

                        <p>En caso de que el usuario sea menor de 18 años el padre o tutor podrá realizar el registro con sus
                        datos, obligándose a los presentes Términos de Uso en representación del menor.</p>

                        <p>El usuario, padre o tutor está enterado que el es único responsable del uso de su cuenta de la
                        plataforma <strong>Kn·U</strong> por lo que en caso de pérdida y/o extravío de su contraseña deberá dar aviso de
                        manera inmediata a <strong>Kn·U</strong> a efecto de notificar tal hecho y solicitar la reposición de la misma mediante
                        los procesos de seguridad previamente establecidos. En caso de que se dé por terminada la relación
                        entre el usuario y <strong>Kn·U</strong> el servicio será suspendido.</p>

                        <p>En virtud de lo anterior, será obligación de el usuario, padre o tutor notificar de forma inmediata a <strong>Kn·U</strong>
                        sobre cualquier uso indebido de la plataforma, de la contraseña o en caso de extravío de los datos de la
                        plataforma que lo identifican y que pongan en riesgo el ingreso seguro a la misma.</p>

                        <p>El usuario, padre o tutor mediante la aceptación del presente documento, manifiesta su voluntad de
                        acatar todas las disposiciones relativas al uso de la plataforma <strong>Kn·U</strong>.</p>

                        <p>KNOTION se reserva el derecho de dejar de brindar el servicio a cualquier persona que haga mal uso
                        de la plataforma <strong>Kn·U</strong> así como a cualquier usuario, padre o tutor que puedan afectar a un tercero, por
                        lo que usuario, padre o tutor deslinda de cualquier responsabilidad a <strong>Kn·U</strong> por el uso para fines distintos
                        o ofrecido por la propia aplicación, así como a sacar a salvo y paz de cualquier demanda o queja.</p>

                        <p>El usuario, padre o tutor acepta que la licencia para el uso de la plataforma tecnológica de la que se
                        otorga la aceptación de los términos y condiciones del presente acuerdo comprende única y
                        exclusivamente por el plazo del curso contratado.</p>

                        <p>El usuario, padre o tutor acepta que KNOTION, podrá ceder parcial o totalmente las obligaciones y
                        derechos que derivan del presente acuerdo.</p>

                        <p>El pago realizado a través de los mecanismos establecidos en el sitio implica la aceptación por parte de
                        el usuario, padre o tutor, de todos los Términos de Uso establecidos en el presente documento.</p>

                        <p>En caso de que el usuario, padre o tutor requiera de un comprobante fiscal emitido de acuerdo a las
                        normativas vigentes, deberá solicitarlo durante la transacción de pago. En caso que se realice el
                        proceso de pago en el sitio sin haber solicitado la factura durante el proceso, ya no podrá solicitarla
                        posteriormente.</p>

                        <p>El usuario, padre o tutor acepta en los presentes términos de uso de la plataforma tecnológica <strong>Kn·U</strong>
                        que:</p>
                        <ol type="a">
                            <li>Los derechos y autorización para el uso de los cursos que ofrece <strong>Kn·U</strong> son intransferibles.</li>
                            <li>El pago comprende única y exclusivamente el curso contratado que corresponda.</li>
                            <li>El pago referido en el punto anterior es un pago único y no reembolsable.</li>
                            <li>Es responsabilidad del padre o tutor supervisar e informar a el usuario respecto del
                            cumplimiento de las políticas del uso de la plataforma tecnológica <strong>Kn·U</strong> y las Políticas de
                            Coursera, y que por el incumplimiento a las mismas acepta que <strong>Kn·U</strong> podrá cancelar o
                            suspender a el usuario el acceso o uso. En virtud de lo anterior, el padre o tutor aceptasupervisar que el usuario no haga uso indebido de la plataforma tecnológica <strong>Kn·U</strong>, en los
                            términos que se señalan más adelante en las políticas de uso de la plataforma tecnológica <strong>Kn·U</strong>.</li>
                            <li>KNOTION, no será responsable de cualquier mal uso de la plataforma tecnológica <strong>Kn·U</strong> por
                            usuarios que pudieran afectar a otro usuario, por lo que libera de cualquier responsabilidad o
                            indemnización a KNOTION.</li>
                        </ol>
                        <h2>POLÍTICAS DE USO DEL SISTEMA KNOTION</h2>

                        <p>KNOTION, respeta los derechos de Propiedad Intelectual por lo que a continuación se establecen las
                        políticas de uso para los usuarios de la plataforma tecnológica <strong>Kn·U</strong>. El usuario, padre o tutor se obliga
                        a respetar de igual forma la propiedad intelectual y con su ejemplo hacer que el usuario respete las
                        siguientes políticas, lineamientos o reglas de uso de la plataforma tecnológica <strong>Kn·U</strong> en beneficio de
                        todos los que integran el sistema.</p>

                        <p>Las siguientes acciones no están permitidas bajo ninguna circunstancia a los usuarios de la plataforma
                        tecnológica <strong>Kn·U</strong>:</p>

                        <ol type="A">
                            <li>Copiar, redistribuir, reproducir, “copiar o pegar ” (“ripping”), grabar, transferir, mostrar al
                            público, transmitir o poner a disposición del público alguna parte de la plataforma tecnológica
                            <strong>Kn·U</strong> o su contenido, o utilizar la plataforma tecnológica <strong>Kn·U</strong> o su contenido de alguna otra
                            manera que no esté expresamente permitido por la plataforma tecnológica <strong>Kn·U</strong> o la ley
                            vigente o que, de alguna manera, infrinja los derechos de propiedad intelectual -como los
                            derechos de autor- de la plataforma tecnológica <strong>Kn·U</strong> o su contenido o de alguna parte de
                            este.</li>
                            <li>Infringir, en materia de derechos de autor, marcas registradas, patentes u otros derechos
                            propietarios según legislaciones aplicables.</li>
                            <li>Usar la plataforma tecnológica <strong>Kn·U</strong> para importar o copiar archivos locales que el usuario no
                            tenga derecho legal a importar o copiar de esta manera.</li>
                            <li>Transferir copias de contenido caché desde un dispositivo autorizado a otro dispositivo por
                            cualquier medio que se realice.</li>
                            <li>Usar técnicas de ingeniería inversa, descompilar, desensamblar, modificar o crear obras
                            derivadas basadas en los cursos que ofrece la plataforma tecnológica <strong>Kn·U</strong>, su contenido o
                            en alguna parte de este, a menos que lo permita la ley.</li>
                            <li>Evadir cualquier tecnología utilizada por la plataforma tecnológica <strong>Kn·U</strong>, sus licenciantes o
                            terceros para proteger el contenido o el servicio.</li>
                            <li>Vender, alquilar, sublicenciar o arrendar alguna parte de la plataforma tecnológica <strong>Kn·U</strong> o su
                            contenido.</li>
                            <li>Evadir las restricciones territoriales impuestas por la plataforma tecnológica <strong>Kn·U</strong> o sus
                            licenciantes o titulares.</li>
                            <li>Incrementar de manera ficticia el recuento de reproducciones o manipular de otra manera la
                            plataforma tecnológica <strong>Kn·U</strong> mediante una secuencia de comandos u otro proceso
                            automatizado.</li>
                            <li>Eliminar o alterar cualquier derecho de autor, marca registrada o aviso de propiedad
                            intelectual incluido en los cursos que ofrece la plataforma tecnológica <strong>Kn·U</strong> o proporcionado
                            a través de este (incluso a fin de ocultar o modificar las indicaciones de titularidad o la fuente
                            del contenido).</li>
                            <li>Proporcionar su contraseña a otra persona o usar el nombre de usuario y contraseña de otra
                            persona.</li>
                            <li>“Rastrear” la plataforma tecnológica <strong>Kn·U</strong> o usar medios automatizados (como por ejemplo,
                            bots, scrapers y spiders) para recopilar información de la plataforma tecnológica <strong>Kn·U</strong>.</li>
                            <li>En caso de que el usuario, padre o tutor considere que algún contenido dentro de la
                            plataforma tecnológica <strong>Kn·U</strong> no cumple con los lineamientos o políticas para el usuario podrá
                            notificarlo a KNOTION a través del correo notificaciones@knotion.com o bien a través de
                            los medios que se provean a través del sitio.</li>
                        </ol>

                        <p>El usuario se obliga a respetar la plataforma tecnológica <strong>Kn·U</strong>, a los propietarios del contenido y a los
                        otros usuarios de la plataforma tecnológica <strong>Kn·U</strong> así como a no participar en actividades o publicar
                        contenido que tenga las siguientes características o que incluya material que:</p>
                        <ol type="A">
                            <li>Sea ofensivo, abusivo, difamatorio, pornográfico, amenazador u obsceno.</li>
                            <li>Sea ilegal, es decir, que su objetivo sea fomentar o cometer un acto ilegal de cualquier tipo,
                            incluidas las violaciones a los derechos de propiedad intelectual, los derechos de privacidad o
                            los derechos de propiedad de KNOTION o de un tercero.</li>
                            <li>Incluye su contraseña o incluye la contraseña de otro usuario o los datos personales de
                            terceros o tiene como objetivo solicitar dichos datos personales.</li>
                            <li>Tenga contenido malintencionado, como malware, troyanos o virus, o impide de otro modo el
                            acceso de un usuario al Servicio.</li>
                            <li>Tenga como finalidad acosar o intimidar a otros usuarios.</li>
                            <li>Usurpe o tergiverse su afiliación con otro usuario, persona o entidad, o resulta de otro modo
                            fraudulento, falso, engañoso o erróneo.</li>
                            <li>Utilice medios automatizados para ficticiamente promocionar el contenido.</li>
                            <li>Entre en conflicto con las políticas o lineamientos determinados por KNOTION.</li>
                        </ol>
                        <p>El usuario, padre o tutor entiende y acepta que al publicar algún contenido antes mencionado,
                        KNOTION quedará facultado para proceder a la cancelación o suspensión inmediata de la cuenta del
                        usuario correspondiente. Asimismo acepta que KNOTION puede recuperar el nombre de usuario por
                        cualquier motivo que por su naturaleza así lo justifique.</p>

                        <p>Las partes, de común acuerdo, convienen en que los derechos y obligaciones que se derivan del
                        presente contrato serán regulados, en lo que él mismo no estipule, por la legislación mexicana.</p>

                        <p>En caso de cualquier controversia por lo que respecta a la interpretación o cumplimiento del presente
                        convenio, las partes acuerdan someterse a la jurisdicción y competencia de los Tribunales del Fuero
                        Común de la ciudad de Morelia, Michoacán, renunciando a cualquier otra jurisdicción que pudiera
                        corresponderles en razón de sus domicilios presentes o futuros o que por cualquier otra causa pudiera
                        corresponderles.</p>
                    </div>
                    <div class="controls">
                    <checkbox :classes="'big-checkbox'" v-model="acceptAgreement">Aceptar términos de uso.</checkbox>
                    <br/><Button class="btn-next" :disabled="!acceptAgreement" @click="next">SIGUIENTE</Button>
                    </div>
                </card>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    data() {
        return {
            acceptAgreement: false,
            clientIP: null
        }
    },
    mounted() {
        this.restartSection()
    },
    methods: {
        async restartSection() {
                try {
                    let response = await this.$kuAPI.getClientIP()
                    this.clientIP = response.data.ip
                } catch(e) {
                    console.log("Unabled to get client IP address from ipify "+e)
                }
        },
        async next() {
            if(this.acceptAgreement && this.clientIP) {
                let dataToSaveAccept = {
                    "fullUrl": "https://ku.knotion.com/aceptAgreement/MWIyOGVmMTctNDU0OC00OWU2LTg3Y2EtYzFjNzJjN2IzMTMy",
                    "checkedTime": Date.now(),
                    "ipAddress": this.clientIP,
                    "browser": null,
                    "browserVersion": null,
                    "userAgent": navigator.userAgent,
                    "engine": null,
                    "engineVersion": null,
                    "os": null,
                    "osVersion": null,
                    "screenPrint": null,
                    "screenResolution": null,
                    "timeZone": null,
                    "language": "es",
                    "latitude": null
                }

                this.$store.commit('SET_ACKNOWLEDGMENT', dataToSaveAccept)
                this.$router.push('/register')

            }  
        }
    }
}
</script>
<style lang="scss" >
.terms-section {
    .form-check-label {
        vertical-align: super;
    }
}
</style>
<style lang="scss" scoped>
.terms-frame{
    margin-top: 50px;
    font-size: 14px;
    width: 100%;
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .2);


    h2{
        font-size: 24px;
    }
    a {
        color: white;
        text-decoration: none;
        &:visited, &:active, &:hover {
            text-decoration: underline;
            color: inherit;
        }
    }
}
.terms-section {
    font-size: 24px;
}


h2 {
    margin: auto;
    text-align: center;
    padding: 20px;

}

.controls {
    margin-top: 30px;
    
    span {
        margin-left: 10px;
    }
}


.btn-next {
    margin-top: 30px;
}
</style>